@import "default-variables";
@import "style-guide";

/*
  GENERIC STYLES
  Do not put any styles specific to angular mater components in here (those go in mat-styles.scss)
*/

.sidebar-header {
    position: sticky;
    top: 0;
    z-index: 1;

    .mat-toolbar {
        h2 {
            margin: 0;
            line-height: 32px;
            font-size: 24px;
            color: map-get($teller-online--dark-palette, 800);

            outline: none;
        }

        .close-button {
            margin-left: auto;
        }
    }
}

.delete-button {
    color: map-get($teller-online--grey-palette, 500);
    
    .teller-online-icon {
        .filled {
            fill: map-get($teller-online--grey-palette, 500);
        }
        .cutout {
            fill: map-get(map-get($teller-online--grey-palette, contrast), 500);
        }
    }
    
    &:hover, &:focus, &:active { 
        color: map-get($teller-online--grey-palette, 600);

        .teller-online-icon {
            .filled {
                fill: map-get($teller-online--grey-palette, 600);
            }
            .cutout {
                fill: map-get(map-get($teller-online--grey-palette, contrast), 600);
            }
        }
    }
}

.help-button {
    color: map-get($teller-online--dark-palette, 800);

    &:hover {
        color: map-get($teller-online--dark-palette, 900);
        background-color: map-get($teller-online--primary-palette, 50);
    }
}


.full-page-modal {
    width: 100%;

    header {
        background: map-get($teller-online--grey-palette, 200);
        color: map-get(map-get($teller-online--grey-palette, contrast), 200);
        padding: 16px;

        @media screen and (min-width: #{$portrait-tablet}) {
            padding: 16px 32px;
        }
    }

    & &__back-btn {
        margin-top: -8px;
        margin-left: -8px;
        padding-left: 8px;
        padding-right: 8px;
    }

    & &__subtitle, & &__title {
        margin: 0;
    }

    & &__title {
        margin-top: 8px;
        font-size: 32px;
    }

    & &__subtitle {
        font-size: 16px;
    }

    & &__title + &__subtitle {
        margin-top: 8px;
    }
}

.mat-sidenav--help {
    .mat-list-item h4.mat-line {
        overflow: auto;
        white-space: normal;
    }
}

.app-root--full-page-modal-active ~ .cdk-overlay-container {
    z-index: -1;
}

.fake-mat-button {
    border-radius: 400px;
    display: inline-block;
    width: 24px;
    height: 24px;

    &.mat-accent {
        background: map-get($teller-online--primary-palette, 800);

        .teller-online-icon {
            height: 12px;
            width: 12px;

            .filled {
                fill: map-get(map-get($teller-online--primary-palette, contrast), 800);
            }
            .cutout {
                fill: map-get($teller-online--primary-palette, 800);
            }
        }
    }
}

.mat-radio-group {
    .mat-card {
        padding: 0;

        .mat-card-header {
            width: 100%;

            .mat-card-header-text {
                width: 100%;
                margin: 0;
            }

            .mat-card-title {
                margin: 0;
                
                .mat-radio-button {
                    width: 100%;

                    label {
                        padding: 16px;
                        width: 100%;
                        display: flex; //instead of inline-flex

                        .mat-radio-label-content {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            column-gap: 8px;
                        }
                    }
                }
            }
        }

        .mat-card-content {
            padding: 0 16px 16px;
        }
    }
}