@import "default-variables";

// BG/text colors are controlled in the custom-styles.css file

// these styles are also used by the skeleton loader when the page is
// initially loaded, so it cannot have the :host binding
.header {
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: white;
    
    header {
        .mat-toolbar {
            background-color: transparent;
            padding: 0;
            position: sticky;
            top: 0;
            z-index: 1;

            // span full width of layout grid
            grid-column-start: 1;
            grid-column-end: -1;
            width: 100%;

            // center the logo on mobile (works because of the help/menu icons being equal on both sides
            justify-content: space-between;
        }

        .header__logo {
            height: calc($mobile-header-height - 8px);

            @media screen and (min-width: #{$portrait-tablet}) {
                height: calc($header-height - 8px);
            }    

            @media screen and (min-width: #{$landscape-tablet}) {
                margin-right: auto;
            } 

            img {
                height: 100%;
            }
        }

        nav {
            display: flex;
            column-gap: 16px;
            margin-right: 16px;
        }

        .help-button {
            @at-root .header header .help-button .mat-button-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}