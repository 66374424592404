@import "default-variables";
@import "header/header.component";
@import "footer/footer.component";

.skeleton-loader {
    display: none;

    .skeleton-help, .skeleton-menu {
        @include skeleton-block;
        display: block;
        background-color: transparent;
        width: 40px;
        height: 40px;
        padding: 0;
        box-sizing: border-box;
        line-height: 40px;
        text-align: center;
        
        i {
            display: inline-block;
            vertical-align: middle;
            height: 24px;
            width: 24px;
            line-height: 24px;
            background-color: map-get($teller-online--dark-palette, 800);
        }
    }
    .skeleton-help {
        i {
            border-radius: 1000px;
        }
    }
    .skeleton-menu {
        @media screen and (min-width: #{$landscape-tablet}) {
            display: none;
        }
    }

    .footer {
        .skeleton-anchor {
            @include skeleton-block;
        }
    }

    .skeleton-cart {
        @include skeleton-block;
        border-radius: 1000px;
        background-color: map-get($teller-online--primary-palette, 800);
        width: 56px;
        height: 56px;
    }
}