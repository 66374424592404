@import "default-variables";

// these styles are also used by the skeleton loader when the page is
// initially loaded, so it cannot have the :host binding
.footer {
	margin-top: auto;
	padding: 24px 0;
	background: white;
	width: 100%;
	border-top: 1px solid map-get($teller-online--grey-palette, 200);

	footer {
		row-gap: 24px;

		.footer__container {
			display: flex;
			align-items: center;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			row-gap: 8px;
	
			// span full width of layout grid
			width: 100%;
			grid-column-start: 1;
			grid-column-end: -1;
	
			.footer__block {
				display: flex;
				align-items: center;
	
				.slash {
					width: 1px;
					height: 16px;
					background-color: $teller-online--dark-type;
					margin: 0 16px;
				}
	
				&:last-child {
					.slash {
						display: none;
					}
				}
			}
		}
	}

	a {
		font-family: "Roboto", sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		line-height: 23px;
		text-align: center;
		letter-spacing: 0.005em;
		// Not using the palette because we explicitly want this to always be teller blue, not client specific
		color: $teller-online--primary-blue;
		position: relative;
		cursor: pointer;

		@media screen and (max-width: #{$desktop - 1}) {
			font-size: 16px;
			line-height: 22px;
		}
	}

	& &__container {
		&.to-legal-links {
			p, a {
				font-family: "Roboto", sans-serif;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 23px;
				text-align: left;
				letter-spacing: 0.005em;
				color: $teller-online--dark-type;
				margin: 0;

				@media screen and (max-width: #{$desktop - 1}) {
					font-size: 12px;
					line-height: 18px;
				}
			}

			.legal-text {
				margin-right: 24px;
				font-weight: bold;
			}
		}
	}
}
